.growl-notification {
  /* background: #363636; */
  /* FOR INEOS - changed background */
  background: rgba(0, 21, 44, 0.9);
  border-radius: 4px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
  min-height: 56px;
  position: fixed;
  width: 320px;
  z-index: 1056;
}
.growl-notification:before {
  border-radius: 4px 0 0 4px;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 4px;
}
.growl-notification__progress {
  border-radius: 4px 4px 0 0;
  display: none;
  height: 4px;
}
.growl-notification__progress.is-visible {
  display: flex;
}
.growl-notification__progress-bar {
  border-radius: 4px 4px 0 0;
  height: 4px;
  width: 0;
}
.growl-notification__body {
  align-items: center;
  display: flex;
  min-height: 56px;
  padding: 13px 25px;
}
.growl-notification__buttons {
  border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  display: none;
}
.growl-notification__buttons.is-visible {
  display: flex;
}
.growl-notification__button {
  align-items: center;
  color: #fff;
  display: flex;
  flex-grow: 1;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  min-height: 39px;
  min-width: 50%;
  text-align: center;
}
.growl-notification__button:hover {
  background: hsla(0, 0%, 100%, 0.05);
  color: #fff;
  text-decoration: none;
}
.growl-notification__button--cancel,
.growl-notification__button--cancel:hover {
  color: hsla(0, 0%, 100%, 0.6);
}
.growl-notification__button + .growl-notification__button {
  border-left: 1px solid hsla(0, 0%, 100%, 0.1);
}
.growl-notification__close {
  cursor: pointer;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  right: 8px;
  top: 8px;
  transition: color 0.1s;
}
.growl-notification__close-icon {
  /* background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='24px' height='24px' viewBox='0 0 24 24' enable-background='new 0 0 24 24' xml:space='preserve'%3E%3Cg id='Bounding_Boxes'%3E%3Cpath fill='none' d='M0,0h24v24H0V0z'/%3E%3C/g%3E%3Cg id='Outline_1_'%3E%3Cpath fill='#fff' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41z'/%3E%3C/g%3E%3C/svg%3E"); */
  /* FOR INEOS - changed background-image */
  background-image: url("img/close.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-flex;
  height: 18px;
  opacity: 0.6;
  width: 18px;
}
.growl-notification__close-icon:hover {
  opacity: 0.8;
}
.growl-notification--closed {
  z-index: 1055;
}
.growl-notification__title {
  color: #fff;
  /* font-size: 18px; */
  /* FOR INEOS - changed font-size */
  font-size: 16px;
  font-weight: 600;
  margin-top: -2px;
}
.growl-notification__desc {
  /* FOR INEOS - added font-size */
  font-size: 15px;
  color: #fff;
}
.growl-notification__title + .growl-notification__desc {
  color: hsla(0, 0%, 100%, 0.8);
}
.growl-notification--close-on-click {
  cursor: pointer;
}
.growl-notification--default:before {
  /* background: #b2b2b2; */
  background: transparent;
}
.growl-notification--default .growl-notification__progress {
  background: hsla(0, 0%, 69.8%, 0.15);
}
.growl-notification--default .growl-notification__progress-bar {
  background: hsla(0, 0%, 69.8%, 0.3);
}
.growl-notification--info:before {
  /* background: #269af1; */
  /* FOR INEOS - changed background */
  background: var(--ineos_secondaryWater);
}
.growl-notification--info .growl-notification__progress {
  background: rgba(38, 154, 241, 0.15);
}
.growl-notification--info .growl-notification__progress-bar {
  background: rgba(38, 154, 241, 0.3);
}
.growl-notification--success:before {
  /* background: #8bc34a; */
  /* FOR INEOS - changed background */
  background: var(--ineos_stateTurf);
}
.growl-notification--success .growl-notification__progress {
  background: rgba(139, 195, 74, 0.15);
}
.growl-notification--success .growl-notification__progress-bar {
  background: rgba(139, 195, 74, 0.3);
}
.growl-notification--warning:before {
  background: #ffc107;
}
.growl-notification--warning .growl-notification__progress {
  background: rgba(255, 193, 7, 0.15);
}
.growl-notification--warning .growl-notification__progress-bar {
  background: rgba(255, 193, 7, 0.3);
}
.growl-notification--danger:before,
.growl-notification--error:before {
  /* background: #ff3d00; */
  /* FOR INEOS - changed background */
  background: var(--ineos_stateError);
}
.growl-notification--danger .growl-notification__progress,
.growl-notification--error .growl-notification__progress {
  background: rgba(255, 61, 0, 0.15);
}
.growl-notification--danger .growl-notification__progress-bar,
.growl-notification--error .growl-notification__progress-bar {
  background: rgba(255, 61, 0, 0.3);
}
.growl-notification--image {
  width: 420px;
}
.growl-notification__image {
  background-position: 50%;
  background-repeat: no-repeat;
  height: 46px;
  margin-right: 17px;
  min-width: 40px;
}
.growl-notification__image--custom {
  background: none !important;
  height: auto;
}
.growl-notification--default .growl-notification__image {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAALwSURBVGhD7ZhNaNNgGMc78Asvu3kSBrW2tQ5Fkk5EXF09OIeyrgxPgidP4tGj9KDMgTOfQ6weFGp1CB4mnR4E50Fc082zF0V0w4+THrI1mR/xeds3Urpn2bKm+gbygz8tyRv6+ydpkjehgICAgIBG+or6AGSB5Nh9vZ8u9g/poj6fLi5aJH339I90sT+QK+YBW94OXcU2gmbuFTXjgaQZv3xVQKqY3SA9QcThu0XiiwKCtsyD7MNGceYL5OeszcKsMQzSL5ulG8NcAWnG3CdqpgByX5tlsfzXAqNzVqdSNvfLmnlaqhi3QOh9s+Ba8bRAb/bJQG9maiE1NGWtJ5iQ23hbIFOax0RXCybkNp4V2NOjZDBJp2BCbuNJgRivjMaTKirpFEzIbVouEOXGB4k8KwVgZ36GXO9K3dlGFZ2JJ5VplgrYLqQEVXQmllR1Jgsk1U9U0RnfF4jz6nMWC8CpfY0qOhPj5VMsFSB7HnbqWCQib6WKaxPnlSusFKBK7oECJzFJp2BCbuNZAYKvHyUIqaFS/5HBSR2TxYIJuY2nBQhRXjlh/6lXy9irpboAPPvDZ7dYNjKSZoowD3jTKLeeeF4gkchtgUvYd0zczsizb3UBzTxLN/uLPFvdBbOwS1DmbbMsFs8LEECy0CzdmNzjL7UfFytGlm6yAsuyOoRyNQ0lJ7G5sJ32FOCVLCZu52LhXRVmYLdz09Ymuokj0oyxG0rchJj/pADH5bfHksoiJk8CN5sLdKgrxl8vdZHiomYst7UAASQfYfI0BTpsQwiVahhOv7tQ5kfbCsDV6AwiXguU+wBneQcdumHkspFoW4Ewl+8EURMrUAsvp+jQlmjry134Mz9F5SFQboIOawnySp2Ikxwt6sfpYm+A+8E5TL4e5Xf8oMzRoWwSPnRjBxyFn3gBOAq8epUOZRc4VV5g8uQIxDh5mA5jF5hcnF8hzqulKKccpkPYpvZsxKuX4cY2Qi6tkR55J10VEBAQ4JZQ6A9aCYkJsv8vUgAAAABJRU5ErkJggg==");
}
.growl-notification--info .growl-notification__image {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGySURBVGhD7ZlNSsRAFIRno97BH0jAlVsPJLpT1DN4BZFk8Apu1Sv4A6LgIZx9Z0Z3E7ul3LQFzxJMd0M++GDAV28q2L3JTEZGRkbyZeeqX63a7rRquse67ebefiDnddPd++89CR1QR2NzutioW/dClg9r4563LhbrqPU7ts+Xa1XrXunCFPqHkP4T4djQRSmddseoZ+MDDz8WpNbfCdSz8YEhL+yv9BfaoZ4NW5CDqGfDwqoHtx/9bL78cv/mnc6oop4NC6vOumX/zZv/zGZUUc+GhVWLf4BwbMJDhPJ71wUeof8Q9WxYOAdRz4aFcxD1bFhYNYbNqKKeDQurxrAZVdSzYWHVGDajino2LKwaw2ZUUc+GhVVj2Iwq6tmwsGoMm1FFPRsWVo1hM6qoZ8PCqjFsRhX1bFhYNYbNqKKeDQurxrAZVdSzYWHVGDajino2LJyDqGfDwjmIejYsnIOoZ8PCqS3/tYr4YosvSWnjjlDPhi5IaNW6p93LfgX1bNiSVIby8ut1tmhIw4X1Z/6ubt3hn37gYEv9wjP8OX+KLh8ounyg6PKBossHii4/MiiTySelDscTxWd4WgAAAABJRU5ErkJggg==");
}
.growl-notification--success .growl-notification__image {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAJCSURBVHja7Ng9aFNRGMbx/7lkEMRBBx1cMlmwDlINpJgUDAhNXEyUqgjWgDg4mFaagCLUDxBsKibQDoogKChF2yi2aRcDFkRotIOti0MkNINgP6ypbU0aj0sSmjQhzUeTO+TZ7suF+7vvOZx77hEk0zNuOSkEDuAAsJPqZAHBlEB4uwwjQwACwD1ufoAQHdQyUvY6W0adItmZV6ggQkqrkhwmVUQKHArQhGoiDmmA7dnlS7onVXn8o6A9u7RDQWWpg+qgXAl8ecPy36XagySSpwEPz9/30zPUxdLq79qBJJJnAS/jX/0ARGZD9PpcBVFbBhr88DiNSSUyG2I6/Kl8UOJfgvnoz6KGaWzyZeYaLBTaTZ3oG0zlgeJrMfqGu7kzcJnI3Peihmk95vxRB8ZGc3lzKL4Wo99/i6nwBNGVRe77XHlRlcAUBH389o7pcDB9HV1ZxPP6Gj8WZrYEUxDUst+M5fDZjNqv5XnuDV5NdyovBlE0ZlNzyNZs34BKDd/MXCg/xtRRNAZAs5mbbM12hICR4IsM1N2BK8QTsYphilqHrHo7x3WZnao0puiF0aq3Y9Gdyb3XqwCmpJX6hP4Ce3dpN9QNja1lY0oCKSjcPPcQ7Z6GdK21qY12U2ftth8CwfU2L9rd+zh20MapIxcr9g3UlP4mCjdO99V3jHWQ6kAa4E/232uOP8pqJaoAk+rpj/ysgHSr5/RDuhWncfStFPJ27c+GRLfLMOYXqcK6Iz0dsK1KjlUEE0jF4zQO+wD+DwA72uQvBABEHAAAAABJRU5ErkJggg==");
}
.growl-notification--warning .growl-notification__image {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAlCAYAAAAnQjt6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAANlSURBVHja7JhLbBVVGIC//8zcmbmPCkJDCw2xF4o12BBIStIFgkpcaaKpRuOKRY0rSYwrQly70sQYwoqFYcejkJDQuFG4VRLizgeKXJvGtCFRsAUu3OfM/CxGoEAvvb0zt2w8yWRO5sw55/v/879mhBjtymeZN0T1K0CNyP7NB8sT7a5lxwER1cPARoAw6vc/FRBjs9Gyon4Q8FwsoZY7QS/yDFXnI0TeA7Y9PMhPGD2GqR+SXZQ6BqKTzlsoR0DWLvHqdWBM9tTOtKzdliEK3oeojLcAAdANnNZJ74NENaKT3l5Uv2nDphqIvCa7q4XYIDqBS9b9Hci3aYdFsrUXZZhGvKPJOm/HgADYwh1nNAEbkVFiN5MAiDAcH0SH44MoPfFBWJ+E+3oJgKQTiyOdbv+DLAtEC14+qY30nNffvkY0fD8xka0nr9U0xOu36T7s8DKQSwilhGW9ILvKV1vWiF7CwQ5OJggB0EXgj+slnNaP5rr7BchI8iYpI1xzP2/paLTg7QP9urM+IvtkT/VoUxCddLaichHo6rC3lhAdkd313x47Gr2QWwcysQIQkb0gE9GeCzSi517OsSb1M+Ureep/A9p5FHs1ZIemmQ+2ySvnb0elX2/fD6QH86x6CfwSVItQmYLqLITl5DZPdUNmC6QHwd0ASB7nj++BHaIFb4z0piNkhiAzAOaRZOvfhNpV8P+Fxhz489EV+qC1R2zQgLhgXLCyYD8LqbXg9ICzHqwF0SCsQqUIt3+F+vSYaMGt3E/1YsDZAF4e0pvA6QVZol7WGmgIWGCcJ7/rz0VarhSh8ieof6/mqYqed2cR+hb3MvNAolRvdLe6wM5FkjdrQQWCOWj8p736P1CbgeBOsxmzohdy6wj8Q6iORmItXqY9FnIkFWnLuPfyEmgDwsrScxcgg5xE7P3yUKYVfQfldWAnkOmQv5QRfkQ5iy8nZG/1r6ZJT49j0eM8jzKEYQCVzdFXv65CZXUQ0A/iWvYDN1eFMBBQrVkppkFvgtxAmUF0CmUK5Reu1YvyLkHsj3CA7z7OhKEuPtcI+uqX5WUXXG39ljASiRDqgtgn0XMj7UXDtkD6uvUM8GaT4VMrVrOGEhwA5hcZmhPlkxUDGTxYv2wstkskfQnkFjAuyvaBTysz7ax5dwBLKR25sKNy9wAAAABJRU5ErkJggg==");
}
.growl-notification--danger .growl-notification__image,
.growl-notification--error .growl-notification__image {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAjCAYAAADmOUiuAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAARISURBVHjaxJh9TNR1HMdf3x8HGNSi0jaXzYoBIYseaDXnHdjMNnuYWrmROUeNrQfWKOcQggxFsSdWW/aHQ6yFm7hWNKq1mDY8DgxBM8p4njvEAVkmhGAHx6c/vjzcwXHHEXd8/vnu83jvvX+f+/w+v69iFiIprGWUSuZXHlXVHPcVpHyCSyKUCH4F4uYZYBMhJKoqRrwFGT7LRJARAHAA8ThJ/18MymoW46QNiCIwcolwYtQx+ubGoJPdAQQHsAQHOXNiUCwkAmeAEAIrDkJIUFW0+8vgh0EABxDGCHv9YlAsbADKCaYIFmXD5pNBWUc4UESwRVEkHgib/ogHyATuIvjyEMls9vqIxcJSoAW4gYWRLgaJU6cZ9MygULCA4ACWcR1veGRQVvEgBnWzersEVgaAWFVNtwtxKLFQIxZELIgkK5FPtot0nxfpvyzS3CDyWopIiiFeZTy/6BURe5PIv0MiFztEdj8/6auvnIwfdoh0togU54msNk3GmDno/mTNbJpwWhB5/yVdoMcuYi3XhYYGRJ5ZpnVruUhXm46xN03aLIgUpmn75V4RW4XI4D9az33aHWCjTef81aP14lxxweAUM0nj20qEWOh0A1jzjU7a8aTWv/9M6++mT8aUFWnboXxxy209o+0vr9T6mxu0fvaEO8Cc9Vp/dZXWu9rd65j5Uf9JItkG3O5G6d+9+nz8RYhaAoVpkKzg24Peu8cwYHk8OK7BuZPaVj+2Rt6xwnNO93l93nTr1PnyiJh5ykDYOi3p8D641AXJG+FIG6QXwKJI3+19fRSELYIBl+Xk2iAMO+DGxWAKnbTfFg1xSbA5S+vtv3ga3nsMhD3THBc74IX7oLQQlAFb82C/1f0H/No9ZMzt4s8oguIGeDYT+v6E/ds8JR4zsFEKnHIzJ62B2PuhOBe23A0djRD7AKx8wseAuKLZinAZpaHhYAqDq33aNy4/lMKBbMhPhedioLl+arUeHOwyKRAZJROD2gkK8krhlqWwaTn0dkLTKYhO1P3oTUad0NUGdybAiofh9zq4N1kzZ292j7V+BdVfe6u2U9XRbwCoGn5CODLh+q5En0WV8NZheGwLjAxDo813H375sT73VcDecsgv0/oXH/mzODRQTQmAyWV7zsLEeiCST3eB0wnr0mBNKlxohZKdYG/yXbzigGZyY4ZuiQutur+Ol81+8YJMBaPTlwUzb6PIX+BX3VFVTarnZcHJe4B9AcFdJYTtM+6D6iRDQPaCwVN8oKro8r3ym7GisAQZnp0R4sdI8vHRZJDJWJMGkb0dU8HNCFBZ+Rn4PIjwTigrR/377HSQDfQHAZwTJ6/7fbOg6ugF3gkCwEOqlrNzu5tJIIyb+Q2ICRC4KziJU7X8Mae7GXUOByqAY0co8AZuVveDY2OnEsXaeYbXwiD3qNMMewsyzbJYDhA9490KzLTNRo75PVGT5QscwH8DAO9QS/SuXPueAAAAAElFTkSuQmCC");
  background-size: 90%;
}
.growl-notification.position-bottom-right.animation-slide-in,
.growl-notification.position-top-right.animation-slide-in {
  animation: position-right-slide-in 0.2s forwards;
  transform: translateX(100%);
}
.growl-notification.position-bottom-right.animation-slide-out,
.growl-notification.position-top-right.animation-slide-out {
  animation: position-right-slide-out 0.2s forwards;
  margin-right: -20px;
  transform: translateX(0);
}
.growl-notification.position-top-center.animation-slide-in {
  animation: position-top-slide-in 0.2s forwards;
  transform: translateY(-100%);
}
.growl-notification.position-top-center.animation-slide-out {
  animation: position-top-slide-out 0.2s forwards;
  margin-top: -20px;
  transform: translateY(0);
}
.growl-notification.position-bottom-center.animation-slide-in {
  animation: position-bottom-slide-in 0.2s forwards;
  transform: translateY(100%);
}
.growl-notification.position-bottom-center.animation-slide-out {
  animation: position-bottom-slide-out 0.2s forwards;
  margin-bottom: -20px;
  transform: translateY(0);
}
.growl-notification.position-bottom-left.animation-slide-in,
.growl-notification.position-top-left.animation-slide-in {
  animation: position-left-slide-in 0.2s forwards;
  transform: translateX(-100%);
}
.growl-notification.position-bottom-left.animation-slide-out,
.growl-notification.position-top-left.animation-slide-out {
  animation: position-left-slide-out 0.2s forwards;
  margin-left: -20px;
  transform: translateX(0);
}
.growl-notification.position-top-center,
.growl-notification.position-top-left,
.growl-notification.position-top-right {
  transition: top 0.2s;
}
.growl-notification.position-bottom-center,
.growl-notification.position-bottom-left,
.growl-notification.position-bottom-right {
  transition: bottom 0.2s;
}
.growl-notification.animation-fade-in {
  animation: fade-in 0.2s forwards;
  opacity: 0;
}
.growl-notification.animation-fade-out {
  animation: fade-out 0.2s forwards;
}
@keyframes position-right-slide-in {
  to {
      transform: translateX(0);
  }
}
@keyframes position-right-slide-out {
  to {
      transform: translateX(100%);
  }
}
@keyframes position-left-slide-in {
  to {
      transform: translateX(0);
  }
}
@keyframes position-left-slide-out {
  to {
      transform: translateX(-100%);
  }
}
@keyframes position-top-slide-in {
  to {
      transform: translateY(0);
  }
}
@keyframes position-top-slide-out {
  to {
      transform: translateY(-100%);
  }
}
@keyframes position-bottom-slide-in {
  to {
      transform: translateY(0);
  }
}
@keyframes position-bottom-slide-out {
  to {
      transform: translateY(100%);
  }
}
@keyframes fade-in {
  to {
      opacity: 1;
  }
}
@keyframes fade-out {
  to {
      opacity: 0;
  }
}

/* FOR INEOS */
/* truncate subtitle */
.custom-notif-subtitle {
  width: 23rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* adjust size of footer text */
.custom-notif-footer {
  margin-top: 10px;
  font-size: 12px;
}